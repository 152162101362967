// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/saschaboch/webdev/waerderlorenz-gatsby3/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/saschaboch/webdev/waerderlorenz-gatsby3/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dienstleistungen-js": () => import("/Users/saschaboch/webdev/waerderlorenz-gatsby3/src/pages/dienstleistungen.js" /* webpackChunkName: "component---src-pages-dienstleistungen-js" */),
  "component---src-pages-impressum-js": () => import("/Users/saschaboch/webdev/waerderlorenz-gatsby3/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/Users/saschaboch/webdev/waerderlorenz-gatsby3/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("/Users/saschaboch/webdev/waerderlorenz-gatsby3/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ueberuns-js": () => import("/Users/saschaboch/webdev/waerderlorenz-gatsby3/src/pages/ueberuns.js" /* webpackChunkName: "component---src-pages-ueberuns-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/saschaboch/webdev/waerderlorenz-gatsby3/.cache/data.json")

